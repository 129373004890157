export enum Direction {
  LEFT = "Left",
  RIGHT = "Right",
}

export enum QuestionType {
  WITHCODE = "WithCode",
  WITHOUTCODE = "WithoutCode",
}

export enum AnswerType {
  SINGLE = "Single",
  MULTIPLE = "Multiple",
  CODE = "Code",
}

export enum SeparatorBackground {
  Gradient1 = "Gradient1",
  Gradient2 = "Gradient2",
  Gradient3 = "Gradient3",
  Gradient4 = "Gradient4",
  Gradient5 = "Gradient5",
  Gradient6 = "Gradient6",
  Gradient7 = "Gradient7",
  Gradient8 = "Gradient8",
  GradientMisc1 = "GradientMisc1",
  GradientMisc2 = "GradientMisc2",
  GradientRed = "GradientRed",
  GradientOrange = "GradientOrange",
  GradientTeal = "GradientTeal",
}

export enum BackgroundGradient {
  Gradient1 = "Gradient1",
  Gradient2 = "Gradient2",
  Gradient3 = "Gradient3",
  Gradient4 = "Gradient4",
  Gradient5 = "Gradient5",
  Gradient6 = "Gradient6",
  Gradient7 = "Gradient7",
  Gradient8 = "Gradient8",
  Gradient9 = "Gradient9",
  Gradient10 = "Gradient10",
  GradientMisc1 = "GradientMisc1",
  GradientMisc2 = "GradientMisc2",
  GradientRed = "GradientRed",
  GradientOrange = "GradientOrange",
  GradientTeal = "GradientTeal",
}
