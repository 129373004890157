import React from "react";
import { getWindow } from "utils";

const window = getWindow();
const breakpoints = {
  xs: 375,
  sm: 600,
  md: 768,
  lg: 1024,
};

const ViewportContext = React.createContext({ width: window.innerWidth, breakpoints });

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = (event) => {
    setTimeout(() => {
      if (width != window.innerWidth) {
        setWidth(window.innerWidth);
      }
    }, 500);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ width, breakpoints }}>{children}</ViewportContext.Provider>;
};

export { ViewportContext, ViewportProvider };
