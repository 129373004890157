import React, { useEffect, useState } from "react";
import logo from "assets/svgs/logo.svg";
import background from "assets/svgs/background-footer.svg";
import styles from "./Footer.module.scss";
import Logo from "components/Logo";
import cn from "utils/classNames";

import facebookLogo from "assets/svgs/facebook-logo.svg";
import linkedinLogo from "assets/svgs/linkedin-logo.svg";
import twitterLogo from "assets/svgs/twitter-logo.svg";

const Footer: React.FC = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.content}>
        <div className={styles.contentHalf}>
          <div className={styles.columnStart}>
            <div className={styles.columnTitle}>Minerva Gate</div>
            <div>
              <div>© 2023 Minerva Gate LLC</div>
              <div>All rights reverved.</div>
            </div>
            <div>
              <Logo className={styles.logo} />
            </div>
          </div>

          <div className={styles.column}>
            <div className={cn(styles.columnTitle, styles.columnTitleMarginBottom)}>Services</div>
            <div className={styles.columnContentMiddle}>
              <div>MVP Development</div>
              <div>Product Development</div>
              <div>Full-Stack Development</div>
              <div>Mobile App Development</div>
              <div>DApp Development</div>
              <div>AI Solutions</div>
              <div>QA & Testing</div>
              <div>UX/UI Design</div>
            </div>
          </div>
        </div>

        <div className={styles.contentHalf}>
          <div className={styles.column}>
            <div className={cn(styles.columnTitle, styles.columnTitleMarginBottom)}>Contact</div>
            <div className={styles.columnTitleMarginBottom}>
              <a href="mailto:contact@minervagate.com">contact@minervagate.com</a>
            </div>
            <div className={styles.columnContentContact}>
              <div className={styles.columnContentEndSection}>
                <div className={styles.columnContentEndSectionTitle}>New York, NY, 10001, USA</div>
                <a href="tel:+1-630-809-5432">+1-630-809-5432</a>
                <a href="tel:+1-347-897-9250">+1-347-897-9250</a>
              </div>
              <div className={styles.columnContentEndSection}>
                <div className={styles.columnContentEndSectionTitle}>Bucharest, Romania</div>
                <a href="tel:+40-729-112-046">+40-729-112-046</a>
              </div>
            </div>
          </div>

          <div className={styles.column}>
            <div className={cn(styles.columnTitle, styles.columnTitleMarginBottom)}>We're on social media</div>
            <div className={styles.socialIcons}>
              {/* <img src={facebookLogo}></img>
              <img src={linkedinLogo}></img>
              <img src={twitterLogo}></img> */}
              <a
                target="_blank"
                href="https://www.linkedin.com/company/minervagate/"
                className={styles.pageLinkDecoration}
              >
                LinkedIn
              </a>
            </div>
            <div className={cn(styles.columnTitle, styles.columnTitleMarginBottom)}>Legal</div>
            <div className={styles.columnContentMiddle}>
              <a target="_blank" href="/terms-and-conditions" className={styles.pageLinkDecoration}>
                Terms and Conditions
              </a>
              <a target="_blank" href="/privacy-policy" className={styles.pageLinkDecoration}>
                Privacy Statement
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
