import React from "react";
import cn from "utils/classNames";
import styles from "./ButtonPrimary.module.scss";

const ButtonPrimary: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className, ...props }) => {
  return (
    <button className={cn(styles.buttonPrimary, className)} {...props}>
      {children}
    </button>
  );
};

export default ButtonPrimary;
