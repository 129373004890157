import React from "react";
import logoDesktop from "assets/svgs/logo-desktop.svg";
import logoMobile from "assets/svgs/logo-mobile.svg";
import useViewport from "shared/hooks/useViewport";

const Logo: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const { width, breakpoints } = useViewport();
  return <img src={width <= breakpoints.sm ? logoMobile : logoDesktop} alt="" className={className} />;
};

export default Logo;
