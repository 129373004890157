export function generateShortUUID() {
  // I generate the UID from two parts here
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  return ("000" + firstPart.toString(36)).slice(-3) + ("000" + secondPart.toString(36)).slice(-3);
}

export function mod(a: number, b: number): number {
  return ((a % b) + b) % b;
}

export function getWindow(): any {
  if (typeof window !== "undefined") {
    return window;
  }
  return {};
}
export function formatHTML(html: string) {
  var indent = "\n";
  var tab = " ";
  var i = 0;
  var pre = [];

  html = html
    .replace(new RegExp("<pre>((.|\\t|\\n|\\r)+)?</pre>"), function (x) {
      pre.push({ indent: "", tag: x });
      return "<--TEMPPRE" + i++ + "/-->";
    })
    .replace(new RegExp("<[^<>]+>[^<]?", "g"), function (x) {
      var ret;
      var tag = /<\/?([^\s/>]+)/.exec(x)[1];
      var p = new RegExp("<--TEMPPRE(\\d+)/-->").exec(x);

      if (p) pre[p[1]].indent = indent;

      if (
        [
          "area",
          "base",
          "br",
          "col",
          "command",
          "embed",
          "hr",
          "img",
          "input",
          "keygen",
          "link",
          "menuitem",
          "meta",
          "param",
          "source",
          "track",
          "wbr",
        ].indexOf(tag) >= 0
      )
        // self closing tag
        ret = indent + x;
      else {
        if (x.indexOf("</") < 0) {
          //open tag
          if (x.charAt(x.length - 1) !== ">")
            ret = indent + x.substr(0, x.length - 1) + indent + tab + x.substr(x.length - 1, x.length);
          else ret = indent + x;
          !p && (indent += tab);
        } else {
          //close tag
          indent = indent.substr(0, indent.length - 1);
          if (x.charAt(x.length - 1) !== ">")
            ret = indent + x.substr(0, x.length - 1) + indent + x.substr(x.length - 1, x.length);
          else ret = indent + x;
        }
      }
      return ret;
    });

  for (i = pre.length; i--; ) {
    html = html.replace(
      "<--TEMPPRE" + i + "/-->",
      pre[i].tag.replace("<pre>", "<pre>\n").replace("</pre>", pre[i].indent + "</pre>"),
    );
  }

  return html.charAt(0) === "\n" ? html.substr(1, html.length - 1) : html;
}

export const NOOP_FUNCTION = () => {};

export function dataURItoBlob(dataURI: any) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}
